var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-card-action"},[(_vm.triggerAction.type === _vm.UserActivationActionType.Message)?[_c('div',{staticClass:"trigger-card-action__message-preview cursor-pointer hover:opacity-0-7 mt-base",on:{"click":_vm.handleOpenToEdit}},[_vm._v(" "+_vm._s(_vm.triggerAction.variants[0].text)+" ")])]:_vm._e(),(_vm.triggerAction.type === _vm.UserActivationActionType.Flow)?[_c('select-input',{staticClass:"activation-action-data__flow-select",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.actionCopy,
          'key': 'flow',
          'options': _vm.$store.getters.flowOptions,
          'clearable': false,
        }
      }}})]:_vm._e(),_c('a-modal',{staticClass:"trigger-card-action__action-modal",on:{"ok":_vm.onOkClick,"afterClose":_vm.onCancelClick},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[(_vm.actionMessage)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.actionMessage,
          'key': 'variants',
          'hasMedia': true,
          'targetToUpload': function () {
            return {
              'target': 'target',
              'target_id': _vm.$store.state.boardsState.activeBoard.board
            }
          },
          'base-api-url': 'api.presscode.app/cabinet',
        }
      }}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }