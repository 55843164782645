
































































































































































import {
  LimitedSubscriptionConfig,
  LimitedSubscriptionNotify,
  LimitedSubscriptionType
} from '@/includes/types/Channel.types'
import formatTime from '@/includes/formatTime'
import { InputSetups } from '@/mixins/input-setups'
import ActivationActionData from 'piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/components/ActivationActionData.vue'
import ActivationActionDataOld from '@/components/BoardPmConfig/ActivationActionData.vue'
import { UserActivationAction, UserActivationActionType } from '@/includes/types/PmConfig.types'
import AddEntityCard from '@/components/AddEntityCard.vue'
import TriggerCardAction from '@/components/TriggerCardAction.vue'
import TypeSelect from '@/components/TypeSelect.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import ChannelSubscriptionPanel from '@/components/HelpMessages/ChannelSubscriptionPanel.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'
import { warningNotification } from '@/includes/services/NotificationService'
import Vue from 'vue'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'

type NotifyStruct = {
  time: number
  guid: string
  action: UserActivationAction
}

@Component({
  components: {
    CenteredColumnLayout,
    PageTitle,
    ConfigField,
    ActivationActionData,
    EmptyData,
    AddEntityCard,
    TriggerCardAction,
    TypeSelect,
    ActivationActionDataOld
  },
  data() {
    return {
      StepUnit,
      ChannelSubscriptionPanel,
      LimitedSubscriptionType,
      EditorButtonsHelpView,
    }
  }
})
export default class ChannelSubscription extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  subscriptionType = LimitedSubscriptionType.None

  subscriptionConfig = {} as LimitedSubscriptionConfig

  actionModalOpen = false

  frontendNotifies: Array<NotifyStruct> = []

  get actionOptions(): Array<SelectOption> {
    return [
      {
        value: UserActivationActionType.Message,
        label: `field_${ UserActivationActionType.Message.toLowerCase() }_action_type`
      },
      {
        value: UserActivationActionType.Flow,
        label: `field_${ UserActivationActionType.Flow.toLowerCase() }_action_type`
      },
    ]
  }

  get subscriptionTypeOptions(): Array<SelectOption> {
    return Object.keys(LimitedSubscriptionType).map(k => ({
      label: this.$t(`field_${ snakeCase(k) }_title`).toString(),
      value: k
    }))
  }

  setHighlight(guid: string): void {
    const item: Vue = this.$refs[`trigger-${ guid }`]![0]
    item.$el.classList.add('notify-invalid')

    setTimeout(() => {
      item.$el.classList.remove('notify-invalid')
    }, 7000)
  }

  validateNotifies(): Promise<void> {
    return new Promise((resolve, reject) => {
      const hasDuplicate = this.frontendNotifies.some((n, _, array) => {
        const notifiesDoubles = array.filter(fr => fr.time === n.time)

        if (notifiesDoubles.length > 1) {
          notifiesDoubles.map(n => n.guid).forEach(this.setHighlight)
          return true
        }

        return false
      })

      if (hasDuplicate) {
        reject()
      }

      resolve()
    })
  }

  saveChannelConfig(): void {
    this.validateNotifies()
      .then(() => {
        this.subscriptionConfig.notify = this.setRawNotifies()

        this.$store.commit('pi/EXEC', {
          'fn': () => {
            this.$store.state.channelsState.activeChannel!.config.limited_subscription_config = this.subscriptionConfig
            this.$store.state.channelsState.activeChannel!.config.limited_subscription_type = this.subscriptionType
          },
        })

        this.$store.dispatch('save_active_channel_config', this.$route)
        this.setLocalSubscriptionConfig()
      })
      .catch(() => warningNotification(this.$t('notifies_non_unique').toString()))
  }

  removeItem(item: NotifyStruct): void {
    this.frontendNotifies = this.frontendNotifies.filter(n => n.guid !== item.guid)
    this.saveChannelConfig()
  }

  handleRemoveItem(item: NotifyStruct): void {
    if (this.subscriptionType !== LimitedSubscriptionType.None) {
      this.removeItemConfirm(item)
    }
  }

  removeItemConfirm(item: NotifyStruct): void {
    this.$confirm({
      title: this.$t('delete_notify_item_warn').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => this.removeItem(item)
    })
  }

  defaultNotifyStruct(): NotifyStruct {
    return cloneDeep({
      guid: Guid.create().toString(),
      time: 0,
      action: {
        type: UserActivationActionType.Message,
        variants: [ { text: 'Текст уведомления, который будет отправлено пользователю. Установите собственное' } ]
      }
    })
  }

  onActionItemClick(item: NotifyStruct, newAction: SelectOption): void {
    item.action.type = newAction.value as UserActivationActionType
  }

  setRawNotifies(): any {
    return Object.fromEntries(this.frontendNotifies.slice().map(n => [ n.time, n.action ]))
  }

  setFrontendNotifies(notifies: LimitedSubscriptionNotify | null): Array<NotifyStruct> {
    if (notifies) {
      return Object.entries(notifies).map(([ time, action ]) => ({
        guid: Guid.create().toString(),
        time: Number.parseInt(time),
        action
      }))
    }

    return []
  }

  addRawNotify(): void {
    const newNotify = this.defaultNotifyStruct()
    this.frontendNotifies.push(newNotify)

    this.$nextTick(() => {
      this.$refs[`trigger-${ newNotify.guid }`]![0].$el.scrollIntoView({ behavior: 'smooth' })
    })
  }

  get defaultSubscriptionConfigStruct(): LimitedSubscriptionConfig {
    return {
      from: formatTime('09:00', 'HH:mm:ss'),
      to: formatTime('21:00', 'HH:mm:ss'),
      goodbye: null,
      notify: null
    }
  }

  requestFlow(): void {
    if (!this.$store.state.flowsState.flows) {
      this.$store.dispatch('requestFlows', { board_key: this.$store.state.boardsState.activeBoard?.board })
    }
  }

  setLocalSubscriptionConfig() {
    const channelConfig = this.$store.state.channelsState.activeChannel!.config

    this.subscriptionType = channelConfig.limited_subscription_type

    if (!channelConfig?.limited_subscription_config) {
      this.subscriptionConfig = cloneDeep(this.defaultSubscriptionConfigStruct)
    } else {
      this.subscriptionConfig = channelConfig.limited_subscription_config
      this.frontendNotifies = this.setFrontendNotifies(channelConfig.limited_subscription_config.notify)
    }
  }

  mounted(): void {
    this.$store.dispatch('AdminTemplate/showSaveConfigButton', this.saveChannelConfig)
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  created(): void {
    this.requestFlow()
    this.setLocalSubscriptionConfig()
  }
}
