







import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class ChannelSubscriptionPanel extends Vue{
}
