import { render, staticRenderFns } from "./ChannelSubscriptionPanel.vue?vue&type=template&id=145dfa5c&scoped=true&"
import script from "./ChannelSubscriptionPanel.vue?vue&type=script&lang=ts&"
export * from "./ChannelSubscriptionPanel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145dfa5c",
  null
  
)

export default component.exports