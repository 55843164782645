var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-subscription"},[_c('page-title',{attrs:{"translation-params":[_vm.$store.state.boardsState.activeBoard.title],"custom-help-message":_vm.ChannelSubscriptionPanel},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":_vm.saveChannelConfig}},[_vm._v(" "+_vm._s(_vm.$t('save_active_channel_config'))+" ")])]},proxy:true}])}),_c('a-card',[_c('select-input',{staticClass:"activation-action-data__flow-select",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'subscriptionType',
          'options': _vm.subscriptionTypeOptions,
          'clearable': false,
        }
      }}}),(_vm.subscriptionType !== _vm.LimitedSubscriptionType.None)?[_c('activation-action-data',{staticClass:"mt-2",attrs:{"disabled":_vm.subscriptionType === 'None',"board":_vm.$store.state.boardsState.activeBoard,"flow-options":_vm.$store.getters.flowOptions,"editor-buttons-help-view":_vm.EditorButtonsHelpView,"buttons":_vm.pmButtons,"get-flow-preview":function (key) { return ("https://api.presscode.app/thumbnail/flow/" + key); },"field":"goodbye"},model:{value:(_vm.subscriptionConfig.goodbye),callback:function ($$v) {_vm.$set(_vm.subscriptionConfig, "goodbye", $$v)},expression:"subscriptionConfig.goodbye"}}),(_vm.subscriptionType !== _vm.LimitedSubscriptionType.None)?_c('config-field',{staticClass:"m-2 mt-5",attrs:{"title":_vm.$t('notify_time'),"help-message":_vm.$t('notify_time_help_message', [_vm.$store.state.boardsState.activeBoard.title])}},[_c('div',{staticClass:"flex flex-col xs:flex-row"},[_c('time-picker-input',{staticClass:"mx-0",attrs:{"setup":{
              'func': _vm.modelSetter,
              'args': {
                'model': _vm.subscriptionConfig,
                'key': 'from',
                'time24hr': true,
                'clearable': true,
                'disabled': _vm.subscriptionType === 'None'
              },
            }}}),_c('time-picker-input',{staticClass:"mx-0 xs:mx-2",attrs:{"setup":{
              'func': _vm.modelSetter,
              'args': {
                'model': _vm.subscriptionConfig,
                'key': 'to',
                'time24hr': true,
                'clearable': false,
                'clearable': true,
                'disabled': _vm.subscriptionType === 'None'
              },
            }}})],1)]):_vm._e(),_c('config-field',{staticClass:"m-2 mt-5",attrs:{"title":_vm.$t('field_subscription_notify_title'),"help-message":_vm.$t('field_subscription_notify_help_message')}},[_c('a-button',{staticClass:"mb-2",attrs:{"slot":"titleButton","type":"primary","icon":"plus","disabled":_vm.subscriptionType === 'None'},on:{"click":_vm.addRawNotify},slot:"titleButton"}),(_vm.frontendNotifies.length === 0)?_c('empty-data'):_vm._l((_vm.frontendNotifies),function(item){return _c('a-card',{key:item.guid,ref:("trigger-" + (item.guid)),refInFor:true,staticClass:"notify-card mb-2"},[_c('a-row',[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('time-unit-input',{attrs:{"setup":{
                  'func': _vm.modelSetter,
                  'args': {
                    'model': item,
                    'key': 'time',
                    'step': _vm.StepUnit.Second,
                    'disabled': _vm.subscriptionType === 'None'
                  }
                }}})],1),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('type-select',{attrs:{"active-item":item.action.type,"dropdown-options":_vm.actionOptions,"disabled":_vm.subscriptionType === 'None'},on:{"item-click":function (newType) { return _vm.onActionItemClick(item, newType); },"setting-click":function($event){_vm.actionModalOpen = true}}}),_c('trigger-card-action',{staticClass:"mt-2",attrs:{"trigger-action":item.action,"action-modal-open":_vm.actionModalOpen,"disabled":_vm.subscriptionType === 'None',"action-type":item.action.type},on:{"update:actionModalOpen":function($event){_vm.actionModalOpen=$event},"update:action-modal-open":function($event){_vm.actionModalOpen=$event},"action-change":function (data) { return item.action = data; }}})],1)],1),_c('a-icon',{attrs:{"slot":"actions","type":"delete"},on:{"click":function($event){return _vm.handleRemoveItem(item)}},slot:"actions"})],1)}),(_vm.frontendNotifies.length > 2)?_c('add-entity-card',{staticClass:"w-full",staticStyle:{"min-height":"48px"},on:{"click":_vm.addRawNotify}}):_vm._e()],2)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }