




























































import { InputSetups } from '@/mixins/input-setups'
import { UserActivationActionFlow, UserActivationActionMessage, UserActivationActionType } from '@/includes/types/PmConfig.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Emit, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component({
  components: {
    ConfigField
  },
  data() {
    return {
      UserActivationActionType,
      snakeCase
    }
  }
})
export default class TriggerCardAction extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop() triggerAction!: UserActivationActionFlow | UserActivationActionMessage

  @Prop() actionType!: UserActivationActionType

  @Prop({ type:Boolean }) disabled!:boolean

  @Watch('actionType')
  onActionTypeChange(value: UserActivationActionType):void {
    this.changeActionCopyStructure(value)
  }

  @PropSync('actionModalOpen', { type: Boolean }) isActionModalOpen!:boolean

  @Watch('isActionModalOpen')
  onActionModalOpenChange(state:boolean):void {
    if (state) {
      this.prepareMessageToEdit()
    }
  }

  @Emit()
  actionChange(value: UserActivationActionFlow | UserActivationActionMessage): UserActivationActionFlow | UserActivationActionMessage {
    return value
  }

  actionCopy: UserActivationActionFlow | UserActivationActionMessage | null = {} as UserActivationActionMessage

  @Watch('actionCopy', { deep: true })
  onActionCopyChange(newActionValue: UserActivationActionFlow | UserActivationActionMessage) {
    this.actionChange(newActionValue)
  }

  actionMessage: UserActivationActionMessage | null = {} as UserActivationActionMessage

  handleOpenToEdit():void {
    if (!this.disabled) {
      this.prepareMessageToEdit()
    }
  }

  isModalOpen = false

  prepareMessageToEdit(): void {
    this.actionMessage = cloneDeep(this.triggerAction as UserActivationActionMessage)

    this.isModalOpen = true
  }

  onOkClick() {
    this.actionCopy = this.actionMessage
    this.isModalOpen = false
  }

  onCancelClick() {
    this.actionMessage = null
    this.isActionModalOpen = false
  }

  changeActionCopyStructure(type: UserActivationActionType): void {
    if (type === UserActivationActionType.Message) {
      this.actionCopy = this.activationActionMessageStructure
    }
    if (type === UserActivationActionType.Flow) {
      this.actionCopy = this.activationActionFlowStructure
    }
  }

  get activationActionMessageStructure(): UserActivationActionMessage {
    return {
      type: UserActivationActionType.Message,
      variants: [ {
        attachments: [],
        text: 'Текст сообщения, который будет отправлен. Задайте собственный.',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
      } ]
    }
  }

  get activationActionFlowStructure(): UserActivationActionFlow {
    return {
      type: UserActivationActionType.Flow,
      flow: this.$store.getters.flowOptions.length ? this.$store.getters.flowOptions[0].value : '',
      output: 0
    }
  }

  created():void {
    this.actionCopy = cloneDeep(this.triggerAction)
  }
}
