import { render, staticRenderFns } from "./TriggerCardAction.vue?vue&type=template&id=14a825ae&scoped=true&"
import script from "./TriggerCardAction.vue?vue&type=script&lang=ts&"
export * from "./TriggerCardAction.vue?vue&type=script&lang=ts&"
import style0 from "./TriggerCardAction.vue?vue&type=style&index=0&id=14a825ae&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a825ae",
  null
  
)

export default component.exports